import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import useFetchInfo from '../../hooks/useFetchInfo.hook';
import CatalogServices from '../../services/general/catalogServices';
import { Context } from '../..';
import { useForm } from 'react-hook-form';
import UserServices from '../../services/general/userServices';
import { observer } from 'mobx-react-lite';
import { toast } from 'react-toastify';
import img from "../../images/placeholder.png"
import ProductFeatures from '../simple/ProductFeatures';
import ProductToCartButton from '../simple/ProductToCartButton';
import ProductRecommendeds from '../complex/ProductRecommendeds';
import ProductReviewCard from '../simple/ProductReviewCard';
import ReviewModal from './ReviewModal';

const ProductViewModal = observer(({show, setShow, slug, setCartOpen, openProduct}) => {
    const {app, user} = useContext(Context);

    const {info, loading} = useFetchInfo(CatalogServices.products.view, slug);

    const [productLoading, setProductLoading] = useState();

    const [modalReview, setModalReview] = useState();

    const [reviewShow, setReviewShow] = useState();

    const {handleSubmit, watch, register} = useForm();

    const handleClose = () => setShow(false);

    const [isFavorite, setIsFavorite] = useState();

    const price_configs = watch('price_configs')

    useEffect(() => {
        if(info){
            if(user.favorites.find(it => it ===info.id)){
                setIsFavorite(true);
            }else{
                setIsFavorite(false);
            }
        }
    }, [info, user.favorites])

    const customSubmit = async(data) => {
        setProductLoading(true);
        const {price_configs, ...rest} = data;

        const newPrice = Array.isArray(price_configs) ? price_configs.map(card => {
            let temp = JSON.parse(card)
            return temp.value
        }) : []

        let newData = {
            uid: user.uid ? user.uid : null,
            product_id: info.id,
            count: 1,
            fields: [],
            price_configs: newPrice
        }

        for(var key in rest){
            newData.fields.push(
                {name: key, value: rest[key]}
            )
        }

        const res = await UserServices.cart.addToCart(newData);

        if (res.statusCode === 200) {
            if(!user.uid){
                res.content.uid && user.setUid(res.content.uid);
            }
            user.setBasketChange(Math.random().toString(16))
            toast.success(app.localizationsItems?.product_add_cart)
        }else{
            toast.error(res.message)
        }
    }

    const addToWishlist = async (e) => {
        e.stopPropagation();
        let temp = isFavorite;
        setIsFavorite(!isFavorite);
        const newData = {
            uid: user.wishlistUid ? user.wishlistUid : null,
            product_id: info.id
        }

        const res = await UserServices.favorites.toggle(newData);

        if (res.statusCode === 200) {
            if(!user.wishlistUid){
                user.setWishlistUid(res.content.uid);
            }
            user.setWishlistCountChange(Math.random().toString(16))
        }else{
            setIsFavorite(temp)
        }
    }

  return (
    info &&
    <>
        <Modal show={show} onHide={handleClose} centered className='product-modal'>
            <div className='close' onClick={handleClose}>
                <i className='icon-close'></i>
            </div>
            <Modal.Body>
                <form className='product-modal-wrapper' onSubmit={handleSubmit(customSubmit)}>
                    <div className='product-heart' onClick={e =>addToWishlist(e)}>
                        <i className={isFavorite ? 'icon-like active' : 'icon-heart'}></i>
                    </div>
                    <div className='position-relative'>
                        <img src={info.detail_image ? info.detail_image : img} alt={info.title} title={info.title}
                            width={320}
                            height={320}
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null; 
                                currentTarget.src = img
                            }}
                        />
                        <div className='modal-states'>
                            {info.state && 
                                <div className="product-state" style={{backgroundColor: `${info.state.subtitle}`}}>
                                    <label>{info.state.name}</label>
                                </div>}
                            {info.bonus &&
                                <div className="product-bonus">
                                    <label>{info.bonus.number} {info.bonus.type === 1 ? '%' : ''} Б</label>
                                </div>
                            }
                        </div>
                    </div>
                    <div className='product-modal-content'>
                        <div className='product-modal-hidden'>
                            <div className='flex'>
                                <span>{info.parent?.title}</span>
                            </div>
                            <div className='availability'>
                                {app.localizationsItems?.availability}: {
                                    info?.shipment?.slug === "unavailable" ?
                                        <span className="text-danger">{app.localizationsItems?.not_available}</span>
                                    : info?.shipment?.slug === "on_wait" ?
                                        <span className="text-waiting">{app.localizationsItems?.on_wait}</span>
                                    :
                                        <span className="text-success">{app.localizationsItems?.in_stock}</span>
                                }
                            </div>
                            <h2>{info.title}</h2>
                            {app.reviewEnabled ? <div className='product-reviews-title' onClick={() => setReviewShow(true)}>{info.reviews?.length} {app.localizationsItems?.review_end}</div> : <></>}
                            <div className='subtitle'>{info.subtitle}</div>
                            <div className='content' dangerouslySetInnerHTML={{__html: info.description}}></div>
                            {info.features?.length > 0 && <ProductFeatures info={info}/>}
                            {info.traits?.map((it, index) =>
                                it.title &&
                                <div key={index} className='product-input'>
                                    <div className='product-title'>{it.title}</div>
                                    <div className='states-flex'>
                                        {it.elements?.map((el, index) =>
                                            <label key={index} className='product-label'>
                                                <input  type="radio" value={el.name} required {...register(it.title)}/>
                                                <span>{el.name}</span>
                                            </label>
                                        )}
                                    </div>
                                </div>
                            )}
                            {info.price_configs?.length >= 1 ?
                                <div className='product-input'>
                                    <div className='product-title'>{app.localizationsItems?.add_taste}</div>
                                    <div className='states-flex'>
                                        {info.price_configs?.map((it, index) =>
                                            <label className='product-checkbox' key={index}>
                                                <input type="checkbox" {...register('price_configs')}
                                                    value={JSON.stringify({ value: it.value, price: it.price})}
                                                />
                                                <span>{it.title} +{it.price} {app.localizationsItems?.currency}</span>
                                            </label>
                                        )}
                                    </div>
                                </div>
                                :
                                <></>
                            }
                            <ProductRecommendeds slug={info.slug} openProduct={openProduct}/>
                        </div>
                        <ProductToCartButton 
                            info={info} 
                            setCartOpen={setCartOpen} 
                            setShow={setShow}
                            price_configs={price_configs}
                        />
                        {app.reviewEnabled ? <div className={reviewShow ? 'product-reviews show' : 'product-reviews'}>
                            <div className='product-reviews-top'>
                                <div  className='product-reviews-top-title' onClick={() => setReviewShow(false)}><i className='icon-chevron-left'></i>{app.localizationsItems?.back_to_description}</div>
                                <h2>{info.title}</h2>
                                <div className='product-reviews-title'>{info.reviews?.length} {app.localizationsItems?.review_end}</div>
                            </div>
                            <div className="product-reviews-bottom">
                                {info.reviews?.map((card, index) =>
                                    <ProductReviewCard
                                        card={card}
                                        key={card.id}
                                    />
                                )}
                            </div>
                            <div className='main-btn product-reviews-button' onClick={() => setModalReview(true)}>{app.localizationsItems?.leave_review}</div>
                        </div> : <></>}
                    </div>
                </form>
            </Modal.Body>
        </Modal>
        {show &&
            <ReviewModal
                show={modalReview}
                setShow={setModalReview}
                id={info.id}
            />
        }
    </>
  )
})

export default ProductViewModal